<template>
  <v-dialog
    v-model="isAddQuantityModalOpen"
    width="500"
  >
    <v-card
      v-if="currentProductItem"
      height="500"
    >
      <v-toolbar
        class="py-2"
        flat
      >
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3">Change quantity</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-0"
        >
          <v-icon @click="close()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex flex-row mx-4">
          <v-img
            class="me-4"
            max-height="100"
            max-width="100"
            v-if="currentProductItem?.image"
            :src="currentProductItem?.image?.url"
          />
          <v-img
            v-else
            class="me-4"
            src="@/assets/product.png"
            max-height="100"
            max-width="100"
          />
          <div class="">
            <label class="ma-1 font-weight-regular">{{
              currentProductItem?.name
            }}</label>
            <br />
            <label class="text--disabled ma-1 body-1">
              ID: {{ currentProductItem.code }} Barcode :
              {{ currentProductItem.barcode }}</label
            >
            <br />
          </div>
        </div>
        <v-spacer></v-spacer>

        <v-row style="position: relative; top: 20px; width: 180px; left: 30px">
          <v-text-field
            ref="Quantity"
            autofocus
            outlined
            dense
            :label="$t('quantity')"
            v-model="current"
            :rules="[rules.toPositive]"
            @keyup.enter="addQuantity"
          ></v-text-field>
        </v-row>
        <v-row style="position: relative; top: 20px; width: 300px; left: 10px">
          <!-- calculator -->
          <div>
            <v-layout
              row
              wrap
              class="justify-end"
            >
              <v-flex>
                <div class="calculator">
                  <div
                    v-for="n in 3"
                    :key="n"
                  >
                    <div
                      @click="append(n)"
                      class="btn"
                      v-ripple
                      role="button"
                    >
                      {{ n }}
                    </div>
                  </div>

                  <div>
                    <div
                      @click="clearAll()"
                      class="btn"
                      v-ripple
                      role="button"
                    >
                      <v-icon large> mdi-backspace-outline </v-icon>
                    </div>
                  </div>

                  <div
                    v-for="n in [4, 5, 6]"
                    :key="n"
                  >
                    <div
                      @click="append(n)"
                      class="btn"
                      v-ripple
                      role="button"
                    >
                      {{ n }}
                    </div>
                  </div>
                  <div>
                    <div
                      @click="close()"
                      class="btn esc"
                      v-ripple
                      role="button"
                    >
                      ESC
                    </div>
                  </div>

                  <div
                    v-for="n in [7, 8, 9]"
                    :key="n"
                  >
                    <div
                      @click="append(n)"
                      class="btn"
                      v-ripple
                      role="button"
                    >
                      {{ n }}
                    </div>
                  </div>

                  <div
                    @click="append(0)"
                    class="twoGrid"
                    v-ripple
                    role="button"
                  >
                    0
                  </div>

                  <div>
                    <div
                      @click="dot()"
                      class="btn"
                      v-ripple
                      role="button"
                    >
                      ,
                    </div>
                  </div>
                  <div
                    @click="addQuantity()"
                    class="quantity"
                    v-ripple
                    role="button"
                  >
                    {{ "OK" }}
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <!-- end calculator -->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";
export default {
  mixins: [validationRules],
  props: {
    isAddQuantityModalOpen: Boolean,
    productItem: Object,
    toggleAddQuantityModal: Function,
  },
  mounted: function () {
    this.currentProductItem = { ...this.productItem };
    this.current = this.currentProductItem.qte_in_order;
  },
  computed: {
    ...mapGetters({
      lots: "lots/list",
    }),
  },
  data() {
    return {
      currentProductItem: null,
      current: 0,
    };
  },
  watch: {},
  methods: {
    close() {
      console.log(this.lots);
      this.toggleAddQuantityModal();
    },
    clearAll() {
      if (this.current > 0) {
        let newNumber = this.current.toString().slice(0, -1);
        if (newNumber == "") {
          newNumber = 1;
        }
        this.current = newNumber;
      } else {
        this.current = 1;
      }
    },
    append(number) {
      if (this.current == 0) this.current = `${number}`;
      else this.current = `${this.current}${number}`;
    },
    dot() {
      if (this.current.indexOf(".") == -1) this.append(".");
    },
    async addQuantity() {
      if (!this.current || this.current < 1) {
        return false;
      }
      if (this.currentProductItem) {
        this.currentProductItem.qte_in_order = this.current;
        this.productItem.qte_in_order = this.current;
      }
      this.toggleAddQuantityModal();
    },
  },
};
</script>