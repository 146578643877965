<template>
  <v-dialog v-model="isProductInfoModalOpen" width="700">
    <v-card v-if="currentProductItem">
      <v-toolbar class="py-2" flat>
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3">Items information</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="mr-0">
          <v-icon @click="close()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex flex-row mx-4">
          <v-img
            class="me-4"
            max-height="100"
            max-width="100"
            v-if="currentProductItem?.image"
            :src="currentProductItem?.image?.url"
          />
          <v-img
            v-else
            class="me-4"
            src="@/assets/product.png"
            max-height="100"
            max-width="100"
          />
          <div class="">
            <label class="ma-1 font-weight-regular">{{
              currentProductItem?.name
            }}</label>
            <br />
            <label class="text--disabled ma-1 body-1">
              ID: {{ currentProductItem.code }} Barcode :
              {{ currentProductItem.barcode }}</label
            >
            <br />
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-row>
          <v-col>
            <v-data-table
              :headers="lotItemsHeaders"
              :items="lots"
              :items-per-page="5"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isProductInfoModalOpen: Boolean,
    productItem: Object,
    toggleProductInfoModal: Function,
  },
  mounted: function () {
    this.currentProductItem = { ...this.productItem };
  },
  computed: {
    ...mapGetters({
      lots: "lots/list",
    }),
  },
  data() {
    return {
      currentProductItem: null,
      lotItemsHeaders: [
        {
          text: this.$t("lot_number"),
          align: "start",
          sortable: true,
          value: "lot_number",
        },
        { text: "VAT", value: "lot_purchase_tva" },
        { text: this.$t("discount"), value: "lot_purchase_discount" },
        {
          text: this.$t("purchase_price_ht"),
          value: "lot_purchase_price_ht",
        },
      ],
    };
  },
  watch: {},
  methods: {
    close() {
      console.log(this.lots);
      this.toggleProductInfoModal();
    },
  },
};
</script>