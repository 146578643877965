<template>
  <v-dialog
    v-model="isPromotionModalOpen"
    width="800"
    height="600"
    persistent
    content-class="dialog-background"
  >
    <v-card>
      <v-toolbar
        class="py-2"
        flat
      >
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3">promotion</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-0"
        >
          <v-icon @click="close()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="8"
            dm="8"
          >
            <v-autocomplete
              clearable
              outlined
              item-text="name"
              item-value="id"
              :items="product.promotions"
              :label="$t('pos_n.select_promotion')"
              no-filter
              v-model="product.promotion"
              class="mr-2"
              return-object
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            dm="4"
            class="mt-4"
            v-if="product?.promotion"
          >
            <span><strong>Total discount amount: </strong></span>
            <span>{{ totalDiscountAmount }}</span>
          </v-col>
        </v-row>
        <v-row>
          Details :
          <br/>
          Product Dispalyed Price With Tax : {{this.product.price * this.product.qte_in_order}} <br/>
          ________________________ Calculation ________________________<br/>
          Product base Price : {{this.product.price_ht * this.product.qte_in_order}} <br/>
          Promotion value : {{ parseFloat(this.product?.promotion?.discount)  }} % <br/>
          Max Discount Amount :{{ this.product?.promotion?.max_discount_amount ?? 0 }} <br/>
          ______________________ _=_ R e s u l t _=_ ______________________<br/>
          Promotion result: {{ totalDiscountAmount }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="close()"
          color="indigo"
          text
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="validate()"
        >
          {{ $t("validate") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isPromotionModalOpen: Boolean,
    togglePromotionModal: Function,
    reCalculate: Function,
    product: Object,
  },
  computed: {
    totalDiscountAmount() {
      if (!this.product?.promotion) return;
      let discountAmount =
        Math.round(
          this.product.price_ht *
            this.product.qte_in_order *
            parseFloat(this.product?.promotion?.discount)
        ) / 100;
      let maxDiscountAmount = this.product?.promotion?.max_discount_amount ?? 0;
      return maxDiscountAmount > 0
        ? Math.min(discountAmount, maxDiscountAmount)
        : discountAmount;
    },
  },
  methods: {
    close() {
      this.togglePromotionModal();
    },
    validate() {
      this.togglePromotionModal();
      this.product.reduction = this.totalDiscountAmount;
      this.$emit("reCalculate", this.product);
      this.$store.dispatch("alerts/success", this.$t("pos_n.promotion_added"));
    },
  },
  data() {
    return {};
  },
};
</script>
<style >
.dialog-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(33, 33, 33, 0.5);
  backdrop-filter: blur(10px);
}
.custom-autocomplete .v-input__control {
  width: 100% !important;
  font-size: 16px !important;
}
.custom-col {
  width: 400px; /* Adjust the width as per your requirement */
}
</style>